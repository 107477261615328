import React from 'react';

const Index = () => {
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace('/sdk/transaction');
    }
  }, []);

  return null;
};

export default Index;
